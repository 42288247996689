import { FilterInfo } from '../entity/FilterInfo'
import { ProductSort } from '../entity/ProductSort'
import { Tag } from "../entity/Tag"
import { network } from "../network/Api"
import { Offer } from '../network/dto/OfferDto'
import { Product } from '../network/dto/ProductDto'
import { TagDto } from '../network/dto/TagDto'
import { eventBus } from "../utils/EventBus"
import { LoginEvent } from "../utils/Events"
import { credentialManager } from "./CredentialManager"
import {PlatformsInfo} from "../entity/PlatformsInfo";

const defaultError = "Ошибка"

export const repository = {
    login: async (email: string, password: string) => {
        const resp = await network.login(email, password)
        if (resp.token) {
            credentialManager.saveToken(resp.token);
            try {
                const user = await network.getUser();
                credentialManager.saveUser(user)
                eventBus.post(LoginEvent)
            } catch (e) {
                credentialManager.saveToken(null)
                throw e
            }
        }
        return resp;
    },

    updateUser: async () => network.getUser()
        .then((user) => {
            credentialManager.saveUser(user)
        }),

    getOffers: async (count: number, offset: number, tag: string | null, query: string | null) => {
        return network.getOffers(count, offset, tag, query)
            .then((response) => {
                if (Array.isArray(response)) {
                    return response as unknown as Offer[]
                } else {
                    throw (response as unknown as BaseResponse).message ?? defaultError
                }
            })
    },

    getProducts: async (count: number, offset: number, tag: string | null, query: string | null, filterInfo: FilterInfo, sort: ProductSort, abortSignal: AbortSignal | undefined) => {
        return network.getProducts(count, offset, tag, query, filterInfo, sort, abortSignal)
            .then((response) => {
                if (Array.isArray(response)) {
                    return response as unknown as Product[]
                } else {
                    throw (response as unknown as BaseResponse).message ?? defaultError
                }
            })
    },

    getOffer: async (offerId: string) => {
        return network.getOffer(offerId)
    },

    getOfferGroup: async (offerId: string) => {
        return network.getOfferGroup(offerId)
    },

    getProduct: async (productId: string) => {
        return network.getProduct(productId)
    },

    getProductReferralLink: async (productId: string) => {
        return network.getProductReferralLink(productId)
    },

    createProductOrder: async (productId: string) => {
        return network.createProductOrder(productId)
    },

    getProductOrderStatus: async(orderId: string, abortSignal: AbortSignal | undefined = undefined) => {
        return network.getProductOrderStatus(orderId, abortSignal)
    },

    getTags: async () => {
        return network.getTags()
            .then((response) => {
                if (Array.isArray(response)) {
                    return (response as unknown as TagDto[]).map((tag): Tag => {
                        return {
                            title: tag.title,
                            key: tag.title,
                            count: tag.offers_count
                        }
                    })
                } else {
                    throw (response as unknown as BaseResponse).message ?? defaultError
                }
            });
    },

    getProductTags: async () => {
        return network.getProductTags()
            .then((response) => {
                if (Array.isArray(response)) {
                    return (response as unknown as TagDto[]).map((tag): Tag => {
                        return {
                            title: tag.title,
                            key: tag.title,
                            count: tag.offers_count
                        }
                    })
                } else {
                    throw (response as unknown as BaseResponse).message ?? defaultError
                }
            })
    },

    getReferralLink: async (offerId: string) =>{
        return network.getReferralLink(offerId)
    },

    getFollowInfo: async (linkId: string) =>{
        return network.getFollowInfo(linkId)
    },

    sendKeyAgain: async (orderId: string) : Promise<void> => {
        return network.sendKeyAgain(orderId).then()
    },

    markPromocodeAsShown: async (promocodeId: string) : Promise<void> => {
        return network.markPromocodeAsShown(promocodeId).then()
    },

    requestPromoCode: async (offerId: string, executionId: string, token?: string) => {
        return network.requestPromoCode(offerId, executionId, token)
    },

    getPaymentStatus: async (orderId: string, abortSignal: AbortSignal | undefined = undefined) => {
        return network.getPaymentStatus(orderId, abortSignal)
    },

    createPayment: async (offerId: string, executionId: string | null) => {
        return network.createPayment(offerId, executionId)
    },

    getProductPlatforms: async () => {
        return Promise.all([
            network.getProductPlatforms().then((response) => response.map((item) => item.title)),
            network.getProductYears().then((response) => response.map((item) => item.title)),
            network.getProductPublishers().then((response) => response.map((item) => item.title)),
            network.getProductPlatformsActivation().then((response) => response.map((item) => item.title))
        ])
            .then((responses) : PlatformsInfo => {
                return {
                    platforms: responses[0],
                    years: responses[1],
                    publishers: responses[2],
                    platformsActivation: responses[3]
                }
            })
    },

    getStatistic: async  (start: string, end: string, abortSignal: AbortSignal | undefined) => {
        return network.getStatistic(start, end, abortSignal)
    },

    getClicksStatistic: async (offerId: string, start: string, end: string) => {
        return network.getClicksStatistic(offerId, start, end)
    },

    getTopSellers: async (yearNumber: number, monthNumber: number) => {
        return network.getTopSellers(yearNumber, monthNumber)
    },

    register: async (company: string, email: string, password: string) => {
        return network.register(company, email, password)
    },

    activate: async (email: string, code: string, employee_number?: string) => {
        const response = await network.activate(email, code, employee_number);
        credentialManager.saveToken(response.token ?? null);
        /*if (response.token) {
            const user = await network.getUser()
            credentialManager.saveUser(user)
        }
        eventBus.post(LoginEvent);*/
        return response;
    },

    reset: async (email: string, password: string, code: string) => {
        const response = await network.reset(email, password, code);
        //credentialManager.saveToken(response.token ?? null);
        //eventBus.post(LoginEvent);
        return response;
    },

    resetGetCode: async (email: String) => {
        return network.resetGetCode(email)
    },

    changePassword: async (oldPassword: string, newPassword: string) => {
        let response = await network.changePassword(oldPassword, newPassword)
        credentialManager.saveToken(response.token)
    }
}
