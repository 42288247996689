import { User } from "../network/dto/UserDto";
import {preferences} from "./Preferences";

export const credentialManager = {
    getToken: (): string | null => preferences.getToken(),
    saveToken: (token: string | null) => preferences.saveToken(token),
    saveUser: (user: User | null) => preferences.saveUser(user),
    getUser: (): User | null => preferences.getUser(),
    logOut: () => {
        preferences.saveToken(null);
        preferences.saveUser(null);
    },
    isLogged: () : boolean => preferences.getToken() !== null
}
