import React, {lazy} from "react";
import {OrderType} from "./stores/OrderStore";
const LoginScreen = lazy(() => import(`./screens/login/LoginScreen`));
const RegisterScreen = lazy(() => import(`./screens/register/RegisterScreen`));
const ResetFlow = lazy(() => import(`./screens/reset/ResetFlow`));
const ResetRequestCodeScreen = lazy(() => import(`./screens/reset/ResetRequestCodeScreen`));
const ResetScreen = lazy(() => import(`./screens/reset/ResetScreen`));
const PasswordScreen = lazy(() => import(`./screens/password/ChangePasswordScreen`));
const CatalogScreen = lazy(() => import(`./screens/catalog/CatalogScreen`));
const OffersScreen = lazy(() => import(`./screens/offer/list/OffersScreen`));
const OfferDetailsScreen = lazy(() => import(`./screens/offer/details/OfferDetailsScreen`));
const ProductsScreen = lazy(() => import(`./screens/products/list/ProductsScreen`));
const ProductDetailsScreen = lazy(() => import(`./screens/products/details/ProductDetailsScreen`));
const OrderPromocodeScreen = lazy(() => import(`./screens/order_promocode/OrderPromocodeScreen`));
const OrderPromocodeSuccessScreen = lazy(() => import(`./screens/order_promocode_success/OrderPromocodeSuccessScreen`));
const OrderScreen = lazy(() => import(`./screens/order/OrderScreen`));
const OrderSuccessScreen = lazy(() => import(`./screens/order_success/OrderSuccessScreen`));
const OrderFeedbackScreen = lazy(() => import(`./screens/order_feedback/OrderFeedbackScreen`));
const OrderErrorScreen = lazy(() => import(`./screens/order_error/OrderErrorScreen`));
const StatisticScreen = lazy(() => import(`./screens/statistic/StatisticScreen`));
const StatisticRangesScreen = lazy(() => import(`./screens/statistic/range/RangesScreen`));
const StatisticTopSellersScreen = lazy(() => import(`./screens/statistic/top/TopSellersScreen`));
const StatisticDetailsScreen = lazy(() => import(`./screens/statistic/details/StatisticDetailsScreen`));
const ProfileFlow = lazy(() => import(`./screens/profile/ProfileFlow`));
const ProfileCommonScreen = lazy(() => import(`./screens/profile/common/ProfileCommonScreen`));
const ProfilePersonalScreen = lazy(() => import(`./screens/profile/personal/ProfilePersonalScreen`));
const ProfileDocumentScreen = lazy(() => import(`./screens/profile/document/ProfileDocumentScreen`));
const ProfileDocumentAddressScreen = lazy(() => import(`./screens/profile/document_address/ProfileDocumentAddressScreen`));
const ProfileWithdrawalMethodsScreen = lazy(() => import(`./screens/profile/withdrawal_methods/ProfileWithdrawalMethodsScreen`));
const ProfileAddWithdrawalMethodScreen = lazy(() => import(`./screens/profile/add_withdrawal_method/ProfileAddWithdrawalMethodScreen`));
const ProfileAgreementsScreen = lazy(() => import(`./screens/profile/agreements/ProfileAgreementsScreen`));
const ProfileAgreementFileScreen = lazy(() => import(`./screens/profile/agreement_file/ProfileAgreementFileScreen`));
const ProfileChangePhoneScreen = lazy(() => import(`./screens/profile/change_phone/ProfileChangePhoneScreen`));
const ProfileChangePasswordScreen = lazy(() => import(`./screens/profile/change_password/ProfileChangePasswordScreen`));
const ProfilePayoutHistoryScreen = lazy(() => import(`./screens/profile/payout_history/ProfilePayoutHistoryScreen`));
const ProfilePayoutActScreen = lazy(() => import(`./screens/profile/payout_act/ProfilePayoutActScreen`));
const ProfilePayoutActFileScreen = lazy(() => import(`./screens/profile/payout_act_file/ProfilePayoutActFileScreen`));

const routes =  [
	{
		path: `/`,
		element: <CatalogScreen />,
		routes: [
			{
				path: ``,
				element: <OffersScreen />
			},
			{
				path: `/offers`,
				element: <OffersScreen />
			},
			{
				path: `/products`,
				element: <ProductsScreen />
			}
		]
	},
	{
		path: `/statistics`,
		element: <StatisticScreen />
	},
	{
		path: `/statistics/ranges`,
		element: <StatisticRangesScreen />
	},
	{
		path: `/statistics/top`,
		element: <StatisticTopSellersScreen />
	},
	{
		path: `/statistics/details/:offerId`,
		element: <StatisticDetailsScreen />
	},
	{
		path: `/profile`,
		element: <ProfileFlow />,
		routes: [
			{
				path: ``,
				element: <ProfileCommonScreen />
			},
			{
				path: `/personal`,
				element: <ProfilePersonalScreen />
			},
			{
				path: `/document`,
				element: <ProfileDocumentScreen />
			},
			{
				path: `/document_address`,
				element: <ProfileDocumentAddressScreen />
			},
			{
				path: `/withdrawal_methods`,
				element: <ProfileWithdrawalMethodsScreen />
			},
			{
				path: `/add_withdrawal_method/:tab?`,
				element: <ProfileAddWithdrawalMethodScreen />
			},
			{
				path: `/agreements`,
				element: <ProfileAgreementsScreen />
			},
			{
				path: `/agreement`,
				element: <ProfileAgreementFileScreen />
			},
			{
				path: `/change_phone`,
				element: <ProfileChangePhoneScreen />
			},
			{
				path: `/change_password`,
				element: <ProfileChangePasswordScreen />
			},
			{
				path: `/payout_history`,
				element: <ProfilePayoutHistoryScreen />
			},
			{
				path: `/payout_act/:id/file`,
				element: <ProfilePayoutActFileScreen />
			},
			{
				path: `/payout_act/:id`,
				element: <ProfilePayoutActScreen />
			},
		]
	},
	{
		path: `/offers/:offerId`,
		element: <OfferDetailsScreen />
	},
	{
		path: `/products/:productId`,
		element: <ProductDetailsScreen />
	},
	{
		path: `/order_promocode/:orderId`,
		element: <OrderPromocodeScreen />
	},
	{
		path: `/promocode_success`,
		element: <OrderPromocodeSuccessScreen />
	},
	{
		path: `/order_product/:orderId`,
		element: <OrderScreen type={OrderType.PRODUCT} />
	},
	{
		path: `/order_sell/:orderId`,
		element: <OrderScreen type={OrderType.SELL_PROMOCODE} />
	},
	{
		path: `/order_share/:orderId`,
		element: <OrderScreen type={OrderType.SHARE_PROMOCODE} />
	},
	{
		path: `/order_success/:orderId`,
		element: <OrderSuccessScreen />
	},
	{
		path: `/order_feedback`,
		element: <OrderFeedbackScreen />
	},
	{
		path: `/order_error`,
		element: <OrderErrorScreen />
	},
	{
		path: `/login`,
		element: <LoginScreen />
	},
	{
		path: `/register`,
		element: <RegisterScreen />
	},
	{
		path: `/reset`,
		element: <ResetFlow />,
		routes: [
			{
				path: ``,
				element: <ResetRequestCodeScreen />
			},
			{
				path: `/code`,
				element: <ResetScreen />
			}
		]
	},
	{
		path: `/password`,
		element: <PasswordScreen />
	}
];
export default routes;