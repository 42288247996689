import {makeAutoObservable, runInAction} from "mobx";
import {repository} from "../data/repo/Repository";
import {queryClient} from "../App";

export enum OrderType {
    PRODUCT,
    SELL_PROMOCODE,
    SHARE_PROMOCODE
}

class OrderStore {
    private abortController: AbortController = new AbortController();
    isStateChecking: boolean = false;
    isKeyResending: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    checkState = async (orderId: string) => {
        runInAction(() => {
            this.isStateChecking = true;
        });
        await queryClient.invalidateQueries([`order`, orderId]);
        runInAction(() => {
            this.isStateChecking = false;
        });
    }

    sendEmailAgain = async (orderId: string) => {
        runInAction(() => {
            this.isKeyResending = true;
        });
        await repository.sendKeyAgain(orderId);
        runInAction(() => {
            this.isKeyResending = false;
        });
    }

    getOrder = async (orderId: string) => {
        runInAction(() => {
            this.abortController.abort();
            this.abortController = new AbortController();
        });
	    return await repository.getProductOrderStatus(orderId, this.abortController.signal);
    }
}

const productOrderViewModel = new OrderStore();
export default productOrderViewModel;